import React from 'react';

// import squareImgSrc from '../style/attachments/images/300x300.png';
// import longImgSrc from '../style/attachments/images/350x150.png';

export default props => {
  return (
    <div className="content-page page">
      <article className="post">
        <h1>About</h1>
        <p>MyPeacePal app was created to help people who meditate to have an easy-to-use timer anywhere and on any device.</p>

        <h3>Works on Any Device</h3>
        <p>The app works on any device including iPhones, Androids, computers and tablets. No installation required.</p>
        <p>For the best experience, we recommend using the latest version of your web browser (Chrome, Safari).</p>

        <h3>Save and Track Your Meditations on Google Fit</h3>
        <p>You can save and track your meditation data by linking the app to your Google Account. Simply sign into your Google account from the app and follow the prompts. Your meditation sessions will be synced to your <a href="http://fit.google.com/" rel="nofollow">fit.google.com</a>account every time you meditate while logged in.</p>
        <p>If you don't want to save your meditations, simply don't login. You can also delete your meditation session data from your Google Fit account at any time.</p>

        <h3>Easy Timer Launch</h3>
        <p>For easy timer access you can add the Timer icon to your phone's screen. You can do this in 2 ways:</p>
        <ol>
          <li>From the app: Initially on the first load, by accepting the pop-up about it.</li>
          <li>From your web browser: Go to <a href="http://app.mypeacepal.com/">app.mypeacepal.com</a> in your browser and use add to home screen function in the browser settings.</li>
        </ol>
      </article>
    </div>
  )
};
