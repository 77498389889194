import { GOOGLE_FIT_DATASOURCE } from './consts';

const DAY_QUANTITY = 1000*60*60*24;

export const parseJSON = (string) => {
  try {
    return JSON.parse(string);
  } catch(err) {
    return null;
  }
};

export const stringifyJSON = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch(err) {
    return null;
  }
};

export const ms2ns = (ms) => ms * 1000000; // milliseconds to nanoseconds

export const ns2ms = (ns) => ns / 1000000; // nanoseconds to milliseconds

export const periodString = (from, to) => {
  from = from || (to && to - DAY_QUANTITY*7) || Date.now() - DAY_QUANTITY*7;
  to = to || Date.now();
  return `${ms2ns(from)}-${ms2ns(to)}`
}

export const fitDataToD3 = (fitData, period = 1) => {
  let maxValue = 40;
  let minPart = Date.now();
  let maxPart = 0;
  let loopLimit = 1000;
  const q = (period > 31? 30: 1) * DAY_QUANTITY;
  const mergedPoints = fitData.point.reduce((prev, el) => {
    const part = Math.floor(ns2ms(el.endTimeNanos)/q);
    minPart = Math.min(minPart, part);
    maxPart = Math.max(maxPart, part);
    prev[part] = (prev[part] || 0) + (ns2ms(el.endTimeNanos - el.startTimeNanos) / 1000 / 60);
    maxValue = Math.max(maxValue, prev[part])
    return prev;
  }, {})

  // fill empty parts
  while(minPart < maxPart && loopLimit) {
    --loopLimit;
    const temp = --maxPart;
    if (!mergedPoints[temp]) {
      mergedPoints[temp] = 0;
    }
  }

  return {
    data: Object.keys(mergedPoints).map(key => {
      return {
        x: new Date(key*q).toISOString(),
        y: mergedPoints[key]
      }
    }),
    maxValue: maxValue
  }
};

export const timerDataToFit = (sourceId, timerData, endTime, startTime) => (
  {
    "dataSourceId": sourceId,
    "maxEndTimeNs": ms2ns(endTime),
    "minStartTimeNs": ms2ns(startTime || endTime - timerData),
    "point": [
      {
        "dataTypeName": GOOGLE_FIT_DATASOURCE.dataType.name,
        "startTimeNanos": ms2ns(startTime || endTime - timerData),
        "endTimeNanos": ms2ns(endTime),
        "originDataSourceId": "",
        "value": [
          {
            "intVal": "45" // It's index of "Meditation" type. More info here https://developers.google.com/fit/rest/v1/reference/activity-types
          }
        ]
      }
    ]
  }
);

export const extractFromProvidersData = (user, key) => (
  user && (
    (
      user.providerData &&
      user.providerData.reduce((prev, el) => prev || el[key], null)
    ) || (
      user.additionalUserInfo &&
      user.additionalUserInfo.profile &&
      user.additionalUserInfo.profile[key]
    )
  )
);