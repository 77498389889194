import { postFitnesDataSet } from './api';
import { timerDataToFit, periodString, parseJSON, stringifyJSON } from './utils';
import { timeStringToSeconds } from './timerEngine';


export const saveResultsToGoogle = (lastCount) => {
  const streamId = localStorage.getItem("data_stream_id");
  const initTime = localStorage.getItem(`main_initial_value`);
  const cachedData = parseJSON(localStorage.getItem(`cached_timer_data`)) || [];
  const ms = lastCount? (timeStringToSeconds(initTime) - timeStringToSeconds(lastCount)) * 1000 : timeStringToSeconds(initTime) * 1000;
  const mergedData = [
    ...cachedData,
    {
      value: ms,
      timeString: periodString(),
      date: Date.now()
    }
  ];
  mergedData.forEach(d => postFitnesDataSet(streamId, d.timeString, stringifyJSON(timerDataToFit(streamId, d.value, d.date))));
  localStorage.removeItem(`cached_timer_data`);
};

export const saveResultsToCache = (lastCount) => {
  const initTime = localStorage.getItem(`main_initial_value`);
  const cachedData = parseJSON(localStorage.getItem(`cached_timer_data`)) || [];
  const ms = lastCount? (timeStringToSeconds(initTime) - timeStringToSeconds(lastCount)) * 1000 : timeStringToSeconds(initTime) * 1000;
  const mergedData = [
    ...cachedData,
    {
      value: ms,
      timeString: periodString(),
      date: Date.now()
    }
  ];
  localStorage.setItem(`cached_timer_data`, stringifyJSON(mergedData));
};