import React from 'react';
import Popup from "reactjs-popup";
import MaterialIcon from '@material/react-material-icon';

import logoSrc from '../style/attachments/images/logo.svg';
import { getUser, signOut } from '../services/auth';

export default class Header extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  async componentDidMount() {
    this.setState({
      userData: await getUser()
    })
  }

  render() {
    const { userData } = this.state;
    const triggerEl = (
      <MaterialIcon
        icon="dehaze"
        className="header-icon-more"
      />
    );
    return (
      <header className="header">
        <a className="header-logo" href="/" title="MyPeacePal">
          <img
            src={logoSrc}
            alt="Peace Pal"
          />
        </a>

        <Popup
          trigger={triggerEl}
          arrow={false}
          overlayStyle={{
            backgroundColor: "rgba(255,255,255,0.5)"
          }}
          position="bottom right"
        >
          <nav className="navigation">
            <a href="/">
              Home
            </a>
            <a href="/about">
              About Us
            </a>
            <a href="/faq">
              FAQ
            </a>
            <a href="/feedback">
              Feedback
            </a>
            <a href="/support">
              Support Project
            </a>

            {userData &&
              <span
                onClick={signOut}
              >
                Logout
              </span>
            }
          </nav>
        </Popup>
      </header>
    )
  }
};
