/**
 * Modified version of:
 * https://github.com/dima-bu/react-time-input/blob/master/src/timeInput.jsx
 */ 
import React from "react";

class TimeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.initTime || ""
    };
    this.lastVal = "";
  }

  componentDidMount() {
    if (!this.props.disabled && this.props.mountFocus) {
      setTimeout(() => {
        this._input.focus();
      }, 0);
    }
  }

  componentDidUpdate() {
    if (this.props.mountFocus) {
      setTimeout(() => {
        this._input.focus();
      }, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initTime) {
      this.onChangeHandler(nextProps.initTime);
    }
  }

  isValid(val) {
    const regexp = /^\d{0,2}?:?\d{0,2}$/;
    let valArr = [],
      [minutesStr, secondsStr] = val.split(":");

    if (!regexp.test(val)) {
      return false;
    }

    const minutes = Number(minutesStr);
    const seconds = Number(secondsStr);

    const isValidMinuteSeconds = timeVal =>
      (Number.isInteger(timeVal) && timeVal >= 0 && timeVal < 60) || Number.isNaN(timeVal);
      
    if (!isValidMinuteSeconds(minutes) || !isValidMinuteSeconds(seconds)) {
      return false;
    }

    if (seconds < 10 && Number(secondsStr[0]) > 5) {
      return false;
    }

    if (valArr.indexOf(":")) {
      valArr = val.split(":");
    } else {
      valArr.push(val);
    }
    return true;
  }

  onChangeHandler(val) {
    if (val === this.state.time) {
      return;
    }
    if (this.isValid(val)) {
      if (
        val.length === 2 &&
        this.lastVal.length !== 3 &&
        val.indexOf(":") === -1
      ) {
        val = val + ":";
      }

      if (val.length === 2 && this.lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      this.lastVal = val;

      this.setState({
        time: val
      });

      if (val.length === 5) {
        this.props.onTimeChange(val);
      }
    }
  }

  getType() {
    if (this.props.type) {
      return this.props.type;
    }
    return "tel";
  }

  render() {
    return (
      <input
        name={this.props.name ? this.props.name : undefined}
        className={this.props.className}
        type={this.getType()}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        value={this.state.time}
        onChange={e => this.onChangeHandler(e.target.value)}
        onFocus={
          this.props.onFocusHandler
            ? e => this.props.onFocusHandler(e)
            : undefined
        }
        onBlur={
          this.props.onBlurHandler
            ? e => this.props.onBlurHandler(e)
            : undefined
        }
        ref={c => (this._input = c)}
      />
    );
  }
}

export default TimeInput;
