import React from 'react';

import { signInGoogle } from '../services/auth';

export default props => {
  const signIn = () => {
    signInGoogle()
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        const { code, message, email, credential } = error;
        console.log(code, message, email, credential);
        props.onError && props.onError(message);
      });
  }

  return (
    <div
      className="google-login-button"
      onClick={signIn}
    />
  )
};