import { getUser, signOut } from "./auth";

export const getFitnesDataSources = () => googleRequest("https://www.googleapis.com/fitness/v1/users/me/dataSources?dataTypeName=com.google.activity.segment");
export const postFitnesDataSource = (data) => googleRequest("https://www.googleapis.com/fitness/v1/users/me/dataSources", data);
export const getFitnesDataSet = (id, period) => googleRequest(`https://www.googleapis.com/fitness/v1/users/me/dataSources/${id}/datasets/${period}`);
export const postFitnesDataSet = (id, period, body) => googleRequest(`https://www.googleapis.com/fitness/v1/users/me/dataSources/${id}/datasets/${period}`, body, 'PATCH');

const googleRequest = async (url, body, method) => {
  const { credential } = await getUser();
  const headers = {
    "Authorization": `Bearer ${credential && credential.accessToken}`,
    "Content-Type": "application/json"
  }

  return fetch(url, {
    headers: headers,
    method: method || (body? "POST": "GET"),
    body: body
  })
    .then(res => {
      if (res.status === 401) {
        console.error(`Error with fetching ${url}`, "Unauthorized or token expired")
        return signOut();
      } else {
        return res.json();
      }
    })
    .catch(err => {
      console.error(`Error with fetching ${url}`, err)
    });
};