
export function secondsToTimeString(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - (minutes * 60);
  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds  < 10 ? "0" + seconds : seconds}`
}

export function timeStringToSeconds(string) {
  const valArr = (string || "00:00").split(':');
  return Number(valArr[0])*60 + Number(valArr[1]);
}