import React from 'react';

export default props => {
  return (
    <div
      className={`tooltip ${props.className}`}
      style={{
        top: props.tooltipTop,
        left: props.tooltipLeft,
      }}
    >
      {props.children}
    </div>
  )
};