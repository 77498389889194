import { database } from './firebase';
import { userKeys } from './consts';
import { extractFromProvidersData } from './utils';

export const extendUserWithAdditionalData = (user) => {
  if (user && user.uid) {
    const initialUserObj = {
      uid: user.uid,
      providerData: user.providerData || null
    };
    user = userKeys.reduce((prev, el) => {
      return {
        ...prev,
        [el]: user[el] || extractFromProvidersData(user, el) || null
      }
    }, initialUserObj);
    
    return database
      .ref(`users/${user.uid}`)
      .update({
        ...user
      });
  } else {
    throw new Error("Incorrect 'user' object provided in 'extendUserWithAdditionalData'")
  }
};

export const getUserAdditionalData = ({user, additionalUserInfo, credential }) => {
  if (user && user.uid) {
    return database
      .ref(`users/${user.uid}`)
      .once('value')
      .then(snapshot => {
        const additional = snapshot.val() || {};
        return {
          ...user,
          ...additional,
          additionalUserInfo,
          credential: credential || additional.credential,
        };
      })
  } else {
    return null
  }
};
