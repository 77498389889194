import React from 'react';

export default props => {
  return (
    <div className="content-page page">
      <article className="post">
        <h1>Support</h1>

        <h3>You can help keep the app free and support its development. </h3>
        <p>Simply shop at <a href="https://mypeacepal.com/" target="_blank" rel="noopener noreferrer">MyPeacePal Shop</a>. The shop is a curated collection of popular products for meditation practices,
         stress management and relaxation. Use affiliate links to shop at Amazon. Every bit counts.<br/><br/>
Thank you!<br/>
The MyPeacePal Team
         </p>
        </article>
    </div>
  )
};
