export const GTM_ID = "GTM-53MN6JS";
export const GOOGLE_FIT_DATASOURCE = {
  "dataStreamName": "mypeacepal__develop",
  "type": "derived",
  "name": "My Peace Pal",
  "application": {
    "detailsUrl": "https://app.mypeacepal.com",
    "name": "My Peace Pal",
    "version": "1"
  },
  "dataType": {
    "field": [
      {
        "name": "activity",
        "format": "integer"
      }
    ],
    "name": "com.google.activity.segment"
  },
  "device": {
    "manufacturer": "Unknown",
    "model": "Unknown",
    "type": "tablet",
    "uid": "1000001",
    "version": "1"
  }
};
export const LOCALSTORAGE_FIELD_NAME = "google_user_data"
export const userKeys = [
  "email",
  "gender",
  "displayName",
  "emailVerified",
  "metadata",
  "address",
  "birthday",
  "description",
  "phoneNumber",
  "photoURL",
  "credential"
];
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const journalPageStates = {
  LIST: "list",
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view"
};
export const SECRET_KEY = 'JOURNAL_$$_SECRET_##_KEY';