import { _auth, auth } from './firebase';
import { extendUserWithAdditionalData, getUserAdditionalData } from './database';

export const signInGoogle = (lang) => {
  auth.languageCode = lang || "en_US";
  const provider = new _auth.GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/fitness.activity.write");
  provider.addScope("https://www.googleapis.com/auth/fitness.activity.read");
  return auth
    .signInWithPopup(provider)
    .then(getUserAdditionalData)
    .then(extendUserWithAdditionalData)
}

export const signOut = () => {
  auth.signOut();
  window.location.reload();
}

export const getUser = () => {
  return new Promise(resolve => {
    auth.onAuthStateChanged(user => resolve({user}))
  })
    .then(getUserAdditionalData)
};
