import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import classNames from 'classnames';
import BgTimerHelper from 'bg-timer-helper';
import Popup from "reactjs-popup";

import TimerDigits from '../components/Timer/TimerDigits';
import NoSleep from '../services/noSleep';
import { saveResultsToGoogle, saveResultsToCache } from '../services/fitness';
import { getUser } from '../services/auth';

export default class Timer extends React.Component {
  constructor() {
    super();
    this.state = {
      prepareTimerStatus: "stopped",
      mainTimerStatus: "stopped",
      showModal: false,
      s: 0
    };

    this.currentTimeString = "";
    this.noSleep = new NoSleep();
    this.bgTimerHelper = new BgTimerHelper();
    this.audio = new Audio("/assets/sounds/bell.mp3");

    this.startTimer = this.startTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.onPrepareTimerFinished = this.onPrepareTimerFinished.bind(this);
    this.onMainTimerFinished = this.onMainTimerFinished.bind(this);
    this.stopAndSaveMeditation = this.stopAndSaveMeditation.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onTimeUpdated = this.onTimeUpdated.bind(this);
    this.confirmStop = this.confirmStop.bind(this);
    this.confirmStopAndSync = this.confirmStopAndSync.bind(this);
  }

  componentWillUnmount() {
    this.noSleep.disable();
    this.bgTimerHelper.disable();
  }

  startTimer() {
    this.shortPrepareFlag = false;
    setTimeout(() => {
      this.shortPrepareFlag = true;
    }, 1000)
    this.noSleep.enable();
    this.bgTimerHelper.enable();
    this.setState({
      prepareTimerStatus: "running",
      mainTimerStatus: "pending"
    })
  }

  pauseTimer() {
    this.noSleep.disable();
    this.bgTimerHelper.disable();
    this.setState({
      prepareTimerStatus: "pending",
      mainTimerStatus: "pending"
    })
  }

  resetTimer() {
    // this.noSleep.disable();
    // this.bgTimerHelper.disable();
    this.setState({
      prepareTimerStatus: "stopped",
      mainTimerStatus: "stopped"
    })
  }

  async onMainTimerFinished() {
    const userLoggedIn = await getUser();
    this.audio.play();
    this.noSleep.disable();
    this.bgTimerHelper.disable();
    this.setState({
      prepareTimerStatus: "ended",
      mainTimerStatus: "ended",
      showModal: !userLoggedIn
    });

    if (userLoggedIn && window.navigator.onLine) {
      saveResultsToGoogle();
    } else {
      saveResultsToCache();
    }
  }

  onPrepareTimerFinished() {
    if (this.shortPrepareFlag) {
      this.audio.play();
    }
    this.setState({
      prepareTimerStatus: "pending",
      mainTimerStatus: "running"
    })
  }

  onClosePopup() {
    this.setState({
      showModal: false,
      modalMessage: null,
      modalConfirm: null
    });
  }

  onTimeUpdated(string) {
    this.currentTimeString = string;
  }

  async stopAndSaveMeditation() {    
    const userLoggedIn = await getUser();
    this.setState({
      prepareTimerStatus: "ended",
      mainTimerStatus: "ended",
      showModal: !userLoggedIn
    });

    if (userLoggedIn && window.navigator.onLine) {
      saveResultsToGoogle(this.currentTimeString);
    } else {
      saveResultsToCache(this.currentTimeString);
    }
  }

  confirmStop() {
    this.setState({
      showModal: true,
      modalMessage: "Reset without saving?",
      modalConfirm: "resetTimer"

    })
  }

  confirmStopAndSync() {
    this.setState({
      showModal: true,
      modalMessage: "Save this session?",
      modalConfirm: "stopAndSaveMeditation"
    })
  }

  render() {
    const { mainTimerStatus, prepareTimerStatus, showModal, modalMessage, modalConfirm } = this.state;

    const contentPopup = modalMessage? (
      <div className="popup-content-wrap">
        {modalMessage}
        <div className="control-icons">
          <MaterialIcon
            icon="cancel"
            className="icon"
            onClick={this.onClosePopup}
          />
          <MaterialIcon
            icon="check_circle"
            className="icon"
            onClick={() => {
              this.onClosePopup();
              this[modalConfirm]();
            }}
          />
        </div>
      </div>
    ) : (
      <div className="popup-content-wrap">
        <h2>You are not logged in.</h2>
        <p>This session will be automatically synchronized after the next logged in session.</p>
        <div className="control-icons">
          <MaterialIcon
            icon="check_circle"
            className="icon"
            onClick={this.onClosePopup}
          />
        </div>
      </div>
    );


    return (
      <div className="timer-page page">
        <Popup
          open={showModal}
          modal
          overlayStyle={{
            backgroundColor: "transparent"
          }}
          onClose={this.onClosePopup}
        >
          {contentPopup}
        </Popup>
        <div className="timer-digits-container">
          <TimerDigits
            className={"main-timer timer-digits"}
            timerType={"main"}
            timerStatus={mainTimerStatus}
            initialTimeString="20:00"
            onFinished={this.onMainTimerFinished}
            onTimeUpdated={this.onTimeUpdated}
          />
          <TimerDigits
            className={"prepare-timer timer-digits"}
            timerType={"prepare"}
            title={"PREPARE"}
            timerStatus={prepareTimerStatus}
            initialTimeString="00:10"
            onFinished={this.onPrepareTimerFinished}
          />
        </div>
        <div className="timer-controls">
          <MaterialIcon
            className={classNames("timer-controls-icon", "additional-icon", {
              "hide-icon": mainTimerStatus !== "pending" || prepareTimerStatus !== "pending"
            })}
            icon="cloud_upload"
            onClick={this.confirmStopAndSync}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", {
              "hide-icon": (mainTimerStatus === "running") || (prepareTimerStatus === "running"),
              "paused-animation": mainTimerStatus === "pending" || prepareTimerStatus === "pending"
            })}
            icon="play_circle_outline"
            onClick={this.startTimer}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", {
              "hide-icon": (mainTimerStatus !== "running") && (prepareTimerStatus !== "running")
            })}
            icon="pause_circle_outline"
            onClick={this.pauseTimer}
          />
          <MaterialIcon
            className={classNames("timer-controls-icon", "additional-icon", {
              "hide-icon": mainTimerStatus === "stopped" || prepareTimerStatus === "stopped" || mainTimerStatus === "ended"
            })}
            icon="stop"
            onClick={this.confirmStop}
          />
        </div>
      </div>
    )
  }
};