import React from 'react';

import TimeInput from './TimeInput';
import { secondsToTimeString, timeStringToSeconds } from '../../services/timerEngine';


export default class TimerDigits extends React.Component {
  constructor() {
    super();
    this.state = {
      s: 0
    };

    this.tikTak = this.tikTak.bind(this);
    this.timeChangeHandler = this.timeChangeHandler.bind(this);
    this.resetTickerInterval = this.resetTickerInterval.bind(this);
    this.setInitialTime = this.setInitialTime.bind(this);
    this.clearTickerInterval = this.clearTickerInterval.bind(this);
  }

  componentDidMount() {
    this.setInitialTime();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timerStatus !== this.props.timerStatus) {
      this.tikTak();
    }
  }

  componentWillUnmount() {
    this.clearTickerInterval();
  }

  tikTak(forcedValue) {
    const { onFinished, timerStatus } = this.props;
    let s = forcedValue || this.state.s;

    this.clearTickerInterval();
    switch (timerStatus) {
      case "ended":
        this.resetTickerInterval(3000);
        return;
      case "stopped":
        this.resetTickerInterval();
        break;
      case "pending":
        break;
      case "running":
        if ((s - 1) <= 0) {
          this.setState({
            s: 0
          });
          onFinished && onFinished();
        } else {
          this.setState({
            s: s - 1
          })
          this.intervalId = setTimeout(() => {
            this.tikTak();
          }, 1000);
        }
        break;
      default:
        break;
    }
  }

  setInitialTime() {
    const { timerType, initialTimeString } = this.props;
    const cachedTimeString = localStorage.getItem(`${timerType}_initial_value`);
    if (cachedTimeString) {
      this.setState({
        s: timeStringToSeconds(cachedTimeString)
      })
    } else {
      localStorage.setItem(`${timerType}_initial_value`, initialTimeString);
      this.setState({
        s: timeStringToSeconds(initialTimeString)
      })
    }
  }

  resetTickerInterval(delay) {
    setTimeout(() => {
      this.setInitialTime();
    }, delay || 0);
  }

  clearTickerInterval() {
    clearTimeout(this.intervalId);
    this.intervalId = null;
  }

  timeChangeHandler(value) {
    const { timerType, timerStatus } = this.props;
    if (timerStatus === "stopped") {
      localStorage.setItem(`${timerType}_initial_value`, value);
      this.setState({
        s: timeStringToSeconds(value)
      })
    }
  }

  render() {
    const { timerType, timerStatus, initialTimeString, title, className, onTimeUpdated } = this.props;
    const time = secondsToTimeString(this.state.s);
    onTimeUpdated && onTimeUpdated(time);
    // console.log(time);
    if (timerStatus === "stopped") {
      const cachedTimeString = localStorage.getItem(`${timerType}_initial_value`);
      const timeString = cachedTimeString || initialTimeString;
      return (
        <div className={className}>
          <div className="timer-title">
            {title}
          </div>
          <TimeInput
            initTime={timeString}
            className="digits"
            onTimeChange={this.timeChangeHandler}
          />
        </div>
      )
    } else {
      return (
        <div className={className}>
          <div className="timer-title">
            {title}
          </div>
          <div className="digits digits-show">
            {time}
          </div>
        </div>
      )
    }
  }
}