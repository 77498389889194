import React from 'react';
import classNames from 'classnames';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

import Card from '../components/Card';

import {dayNames, journalPageStates, monthNames} from '../services/consts';
import {DatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import {deleteNote, getNotes, saveNote} from "../services/journal";
import {getUser} from "../services/auth";
import Auth from "../components/Auth";
import {parseJSON} from "../services/utils";

class MyUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'MMMM d, yyyy', { locale: this.locale });
  }
}

const getToday = (val = new Date()) => {
  const date = new Date(val);

  return monthNames[date.getMonth()] + " " + date.getFullYear();
};

function toArray(obj) {
  const array = [];
  for (let i = obj.length >>> 0; i--;) {
    array[i] = obj[i];
  }
  return array;
}

export default class JournalPage extends React.Component {
  toastId = null;

  constructor() {
    super();

    this.state = {
      notes: [],
      state: journalPageStates.LIST,
      selectedNote: null,
      multi: 1,
      visibleNotesCount: 0,
      maxNotesCount: 0
    };

    this.iScroll = React.createRef();

    this.selectNote = this.selectNote.bind(this);
    this.goToList = this.goToList.bind(this);
    this.editNote = this.editNote.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.createNote = this.createNote.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.getNoteFullDate = this.getNoteFullDate.bind(this);
    this.authError = this.authError.bind(this);
    this.refresh = this.refresh.bind(this);
    this.calc = this.calc.bind(this);
  }

  componentDidMount() {
    const ref = this.iScroll.current;

    ref.addEventListener("scroll", () => {
      this.iScroll.current && this.calc(this.iScroll.current.children[0].children);
      if (ref.scrollTop + ref.clientHeight >= ref.scrollHeight) {
        const {multi, visibleNotesCount, maxNotesCount} = this.state;
        if (maxNotesCount > visibleNotesCount)
          this.setState({multi: multi + 1}, () => this.refresh());
      }
    });

    this.refresh();
  }

  componentWillUnmount() {
    this.iScroll && this.iScroll.removeEventListener("scroll");
  }

  calc(elements) {
    const {notes} = this.state;

    const top = this.iScroll.current.getBoundingClientRect().top;

    toArray(elements).forEach((el, index) => {
      const elTop = el.getBoundingClientRect().top;
      if (elTop === top || (elTop >= top-30 && elTop <= top+30)) {
        this.setState({currentDate: notes[index].date});
      }
    });
  }

  async refresh() {
    const user = await getUser();
    const {currentDate} = this.state;

    if (user) {
      this.setState({
        loading: true,
        userData: user
      }, async () => {
        const {notes, count: maxNotesCount} = await getNotes(user, this.state.multi);

        this.setState({
          notes,
          maxNotesCount,
          visibleNotesCount: notes.length,
          loading: false,
          currentDate: currentDate ? currentDate : notes[0].date
        });
      });
    }
  }

  authError(message) {
    const parsed = parseJSON(message);
    this.setState({
      errorData: (parsed && parsed.error && parsed.error.message) || "Auth error"
    })
  }

  selectNote(note) {
    this.setState({
      state: journalPageStates.VIEW,
      selectedNote: Object.assign({}, note)
    })
  }

  goToList() {
    this.setState({state: journalPageStates.LIST, selectedNote: null})
  }

  editNote(value) {
    const {selectedNote} = this.state;
    const {date = selectedNote.date, text = selectedNote.text} = value;

    this.setState({selectedNote: Object.assign(selectedNote, {date, text})});
  }

  async saveNote() {
    const {selectedNote, userData} = this.state;

    if (selectedNote.text.length) {
      await saveNote(userData, selectedNote);
      toast.update(this.toastId, {
        render: "Success!",
        type: toast.TYPE.SUCCESS
      });
      await this.refresh();
      this.goToList();
    } else if (this.toastId === null)
        this.toastId = toast.error(<span style={{fontSize: 16 + 'px'}}>You cannot save note with empty text!</span>);
      else
        toast.update(this.toastId, {});
  }

  createNote() {
    this.setState({state: journalPageStates.CREATE, selectedNote: {text: '', date: new Date()}})
  }

  async deleteNote() {
    const {selectedNote: {id}, userData} = this.state;

    swal({
      title: "Are you sure?",
      text: "Confirm day entry deletion.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then( async (willDelete) => {
        if (willDelete) {
          await deleteNote(userData, id);
          await this.refresh();
          this.goToList();
        }
      });
  }

  getHeader() {
    const {selectedNote: note, state, currentDate} = this.state;

    if (note && state !== journalPageStates.VIEW) {
      return (
        <div className="date-picker-container">
          <MuiPickersUtilsProvider utils={MyUtils}>
            <i className="material-icons">calendar_today</i>
            <DatePicker
              className="date-picker"
              format="MMMM d, yyyy"
              value={note.date}
              onChange={(date) => this.editNote({date})} />
          </MuiPickersUtilsProvider>
        </div>
      )
    } else {
      return state === journalPageStates.LIST ? getToday(currentDate) : dayNames[note.date.getDay()];
    }
  }

  getNoteFullDate() {
    const {selectedNote: {date}} = this.state;

    return monthNames[date.getMonth()].substring(0, 3) + " " + date.getDate() + ", " + date.getFullYear();
  }

  render() {
    const {notes, state, selectedNote, loading, userData, errorData} = this.state;

    const isList =   state === journalPageStates.LIST;
    const isCreate = state === journalPageStates.CREATE;
    const isEdit =   state === journalPageStates.EDIT;
    const isView =   state === journalPageStates.VIEW;

    const navBar = (
      <div className={classNames("journal-nav", {"center": isList})}>
        {!isList && <i className="material-icons" onClick={this.goToList}>arrow_back</i>}
        {isEdit ?
          <i className="material-icons" onClick={this.deleteNote}>delete</i> :
          <h1>{isView ? this.getNoteFullDate() : 'Journal'}</h1>
        }
        {(isCreate || isEdit) && <i className="material-icons" onClick={this.saveNote}>done</i>}
        {isView && <i className="material-icons" onClick={() => this.setState({state: journalPageStates.EDIT})}>edit</i>}
      </div>
    );

    const notesList = (
      <div className="notes-container">
        {notes.map(note => (
          <div className="note" onClick={() => this.selectNote(note)} key={note.id}>
            <div className="date-wrapper">
              <div className="date">{note.date.getDate()}</div>
              <span>{dayNames[note.date.getDay()].substring(0, 2)}</span>
            </div>
            <div className="content">
              <span>{note.text}</span>
            </div>
          </div>
        ))}
      </div>
    );

    const viewNote = <div className="selected-note">{selectedNote && selectedNote.text}</div>;

    const createOrEditNote = (
      <div className="edit-note">
        <textarea value={selectedNote && selectedNote.text} autoFocus spellCheck={false}
                  onChange={(e) => this.editNote({text: e.target.value})} />
      </div>
    );

    const floatingBtn = isList && (
      <button className="add-journal-btn" onClick={this.createNote}>
        <i className="material-icons">note_add</i>
      </button>
    );

    const spinner = loading && (
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle"/>
        <div className="sk-circle2 sk-circle"/>
        <div className="sk-circle3 sk-circle"/>
        <div className="sk-circle4 sk-circle"/>
        <div className="sk-circle5 sk-circle"/>
        <div className="sk-circle6 sk-circle"/>
        <div className="sk-circle7 sk-circle"/>
        <div className="sk-circle8 sk-circle"/>
        <div className="sk-circle9 sk-circle"/>
        <div className="sk-circle10 sk-circle"/>
        <div className="sk-circle11 sk-circle"/>
        <div className="sk-circle12 sk-circle"/>
      </div>
    );

    const googleAuth = (
      <div className={classNames("login-overlay", "flex-center", {
        "hidden": userData
      })}>
        <Auth
          onError={this.authError}
        />
        <div className="login-error-block">
          {errorData}
        </div>
      </div>
    );

    let content;

    content = (isList && notesList) || (isView && viewNote) || ((isEdit || isCreate) && createOrEditNote);

    return (
      <div className="journal-page page">
        {navBar}
        <div className="card-container">
          <Card header={this.getHeader()} scrollRef={isList && this.iScroll} noScroll={!!isEdit}>
            {content}
            {spinner}
            {floatingBtn}
            {googleAuth}
          </Card>
        </div>
      </div>
    )
  }
}
